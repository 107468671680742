// Import Modules
import { useEffect } from 'react';
import { RouteObject, useNavigate, Outlet } from 'react-router-dom';

const Subscriptions: RouteObject = {
  path: 'subscriptions',
  Component: () => {
    const navigate = useNavigate();

    useEffect(() => {
      window.paymentCallback = (data) => {
        navigate('/subscriptions/status', { state: data, replace: true });
      };
    }, []);

    return <Outlet />;
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const SubscriptionsHome = await import('./Home/Home');
        return { Component: SubscriptionsHome.default };
      },
    },
    {
      path: 'info',
      lazy: async () => {
        const SubscriptionsInfo = await import('./Info/Info');
        return { Component: SubscriptionsInfo.default };
      },
    },
    {
      path: 'info-v2',
      lazy: async () => {
        const SubscriptionsInfo = await import('./InfoV2/InfoV2');
        return { Component: SubscriptionsInfo.default };
      },
    },
    {
      path: 'redirect',
      lazy: async () => {
        const SubscriptionsRedirect = await import('./Redirect/Redirect');
        return { Component: SubscriptionsRedirect.default };
      },
    },
    {
      path: 'refer',
      lazy: async () => {
        const SubscriptionsRefer = await import('./Refer/Refer');
        return { Component: SubscriptionsRefer.default };
      },
    },
    {
      path: 'status',
      lazy: async () => {
        const SubscriptionStatus = await import('./Status/Status');
        return { Component: SubscriptionStatus.default };
      },
    },
    {
      path: 'statusRzp',
      lazy: async () => {
        const SubscriptionStatusRzp = await import('./Status/StatusRzp');
        return { Component: SubscriptionStatusRzp.default };
      },
    },
    {
      path: 'upgrade',
      lazy: async () => {
        const SubscriptionUpgrade = await import('./Upgrade/Upgrade');
        return { Component: SubscriptionUpgrade.default };
      },
    },
    {
      path: 'validity',
      lazy: async () => {
        const Validity = await import('./Validity/Validity');
        return { Component: Validity.default };
      },
    },
    {
      path: 'renew',
      lazy: async () => {
        const Renewal = await import('./Renewal/Renewal');
        return { Component: Renewal.default };
      },
    },
    {
      path: 'plus-to-pro',
      lazy: async () => {
        const PlusToPro = await import('./PlusToPro/PlusToPro');
        return { Component: PlusToPro.default };
      },
    },
    {
      path: 'renew-ultra',
      lazy: async () => {
        const RenewUltra = await import('./RenewUltra/RenewUltra');
        return { Component: RenewUltra.default };
      },
    },
  ],
};

export default Subscriptions;
