// Import Modules
import { Outlet, RouteObject } from 'react-router-dom';

import { CDN_ENDPOINT } from 'config/Endpoints';

const AutoReload: RouteObject = {
  path: 'auto-reload',
  lazy: async () => {
    const { ParentContainer, Container } = await import('./AutoReload.style');
    return {
      Component: () => (
        <ParentContainer>
          <Container>
            <Outlet />
          </Container>
        </ParentContainer>
      ),
    };
  },
  children: [
    {
      index: true,
      path: '*',
      lazy: async () => {
        const AutoReloadHome = await import('routes/AutoReload/AutoReloadHome/AutoReloadHome');
        return { Component: AutoReloadHome.default };
      },
    },
    {
      path: 'terms',
      lazy: async () => {
        const AutoReloadTerms = await import('routes/AutoReload/AutoReloadTerms/AutoReloadTerms');
        return { Component: AutoReloadTerms.default };
      },
    },
    {
      path: 'intro-video',
      lazy: async () => {
        const IntroVideo = (await import('components/GeneralComponents/IntroVideo/IntroVideo')).default;
        return {
          Component: () => (
            <IntroVideo
              keyName="auto-load-intro-video-displayed"
              redirectUrl="/auto-reload"
              videoUrl={`${CDN_ENDPOINT}/auto-reload/assets/AutoLoadExplainer.mp4`}
              event="Auto-Reload"
            />
          ),
        };
      },
    },
  ],
};

export default AutoReload;
