// Import Modules
import { useEffect } from 'react';
import { Outlet, RouteObject, useNavigate } from 'react-router-dom';

const FAQ: RouteObject = {
  path: 'faq',
  Component: () => {
    return (
      <div style={{ background: '#fff', minHeight: '100vh' }}>
        <Outlet />
      </div>
    );
  },
  children: [
    {
      index: true,
      Component: () => {
        const navigate = useNavigate();
        useEffect(() => {
          navigate('/faq/android', { replace: true });
        }, []);
        return null;
      },
    },
    {
      path: 'search',
      lazy: async () => {
        const FAQSearch = await import('routes/FAQ/FAQSearch/FAQSearch');
        return { Component: FAQSearch.default };
      },
    },
    {
      path: 'search/answer/:questionId',
      lazy: async () => {
        const FAQSearchAnswer = await import('routes/FAQ/FAQSearchAnswer/FAQSearchAnswer');
        return { Component: FAQSearchAnswer.default };
      },
    },
    {
      path: ':slug',
      lazy: async () => {
        const FAQPage = await import('routes/FAQ/FAQPage/FAQPage');
        return { Component: FAQPage.default };
      },
    },
    {
      path: ':slug/subcategories',
      lazy: async () => {
        const FAQSubCategoryPage = await import('routes/FAQ/FAQSubCategories/FAQSubCategories');
        return { Component: FAQSubCategoryPage.default };
      },
    },
    {
      path: ':slug/questions',
      lazy: async () => {
        const FAQQuestions = (await import('routes/FAQ/FAQQuestions/FAQQuestions')).default;
        return { Component: () => <FAQQuestions isAnswer={false} /> };
      },
    },
    {
      path: ':slug/questions/:questionid',
      lazy: async () => {
        const FAQQuestions = (await import('routes/FAQ/FAQQuestions/FAQQuestions')).default;
        return { Component: () => <FAQQuestions isAnswer /> };
      },
    },
    {
      path: '*',
      lazy: async () => {
        const FAQ404 = await import('routes/FAQ/FAQ404/FAQ404');
        return { Component: FAQ404.default };
      },
    },
  ],
};

export default FAQ;
