// Import Modules
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useRouteError } from 'react-router-dom';

// Import Components
import Button from 'components/Design/Button/Button';

// Import Utils
import NativeActions from 'utils/NativeActions';

// Import Media
import ErrorCubeImg from 'assets/images/ErrorCube.svg';

// Import Styles
import { ErrorStyles } from './ErrorStyles.styles';

const Error = ({ error = {} }) => {
  const [showError, setShowError] = useState(false);
  const reactRouterError = useRouteError();

  const navigate = useNavigate();
  const location = useLocation();

  const handleError = (errorText) => {
    const chunkErrorCount = parseInt(sessionStorage.getItem('chunkErrorCount') || 0, 10) || 0;
    if (errorText.includes('imported module') && chunkErrorCount < 3) {
      sessionStorage.setItem('chunkErrorCount', chunkErrorCount + 1);
      NativeActions.showToast(`New version available, reloading`);
      window.location.reload(true);
    }
    NativeActions.trackEvent('Error', { error: errorText, path: window?.location?.pathname });
  };

  useEffect(() => {
    if (error?.message || reactRouterError?.message) {
      const errorMsg = error?.message || reactRouterError?.message;
      Sentry.captureException(error || reactRouterError);
      handleError(errorMsg);
    }
  }, []);

  const handleOnBack = () => {
    if (location.key === 'default') window.location.replace('fp://fampay.in/webview_close/');
    else navigate(-1);
  };

  return (
    <ErrorStyles showError={showError}>
      <div className="heading">Something is broken</div>
      <div className="subheading" role="presentation" onClick={() => setShowError(true)}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {showError ? error?.message || reactRouterError?.message : 'See what happened'}
        {showError ? <div>Path: {window.location.pathname}</div> : null}
      </div>
      <img src={ErrorCubeImg} alt="error-img" />
      <Button variant="secondary" size="xl" onClick={handleOnBack}>
        Go back
      </Button>
    </ErrorStyles>
  );
};

export default Error;
