// Import Modules
import { useEffect } from 'react';
import { Outlet, RouteObject, useLocation } from 'react-router-dom';
import queryString from 'query-string';

// Import Utils
import NativeActions from 'utils/NativeActions';

const KYCPaywall: RouteObject = {
  path: 'kyc-paywall',
  Component: () => {
    const location = useLocation();
    const { search } = location;
    const { application_id } = queryString.parse(search);

    useEffect(() => {
      NativeActions.trackEvent('KYC_Event', { event: 'Webview Opened', application_id });
    }, [application_id]);

    return <Outlet />;
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const PaywallStatus = await import('routes/KYCPaywall/PaywallStatus/PaywallStatus');
        return { Component: PaywallStatus.default };
      },
    },
    {
      path: 'create',
      lazy: async () => {
        const PaywallCreate = await import('routes/KYCPaywall/PaywallCreate/PaywallCreate');
        return { Component: PaywallCreate.default };
      },
    },
  ],
};

export default KYCPaywall;
