// Import Modules
import { RouteObject } from 'react-router-dom';

const AppCustomisations: RouteObject = {
  path: 'app-customisations/intro',
  lazy: async () => {
    const AppCustomisationsIntro = await import(
      'routes/AppCustomisations/AppCustomisationsIntro/AppCustomisationsIntro'
    );
    return { Component: AppCustomisationsIntro.default };
  },
};

export default AppCustomisations;
