// Import Modules
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Outlet, RouteObject, useNavigate } from 'react-router-dom';

// Import Utils
import NativeActions from 'utils/NativeActions';

const Electricity: RouteObject = {
  path: 'electricity',
  lazy: async () => {
    const { AnimatePresence } = await import('framer-motion');
    return {
      Component: () => {
        const navigate = useNavigate();
        const queryClient = useQueryClient();

        useEffect(() => {
          NativeActions.trackEvent('Electricity', { screen: 'Home Page', action: 'Opened' });
          window.paymentCallback = (data) => {
            queryClient.invalidateQueries();
            // const data = {transaction_id: 123, display_transaction_id: "123",  extra_data: { bill_payment_reference_number:  'eyJyZWZlcmVuY2VfbnVtYmVyIjogIjM0OUEwQTRCMTM4MTRGQ0UifQ=='}}
            navigate('/electricity/status', { state: data });
          };
        }, []);
        return (
          <AnimatePresence initial={false}>
            <Outlet />
          </AnimatePresence>
        );
      },
    };
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const States = await import('routes/Electricity/States/States');
        return { Component: States.default };
      },
    },
    {
      path: 'status',
      lazy: async () => {
        const Status = await import('routes/Electricity/Status/Status');
        return { Component: Status.default };
      },
    },
    {
      path: 'biller/:id',
      lazy: async () => {
        const Biller = await import('routes/Electricity/Biller/Biller');
        return { Component: Biller.default };
      },
    },
    {
      path: 'bill/:id',
      lazy: async () => {
        const BillPage = await import('routes/Electricity/BillPage/BillPage');
        return { Component: BillPage.default };
      },
    },
  ],
};

export default Electricity;
