// Import Modules
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Outlet, useNavigate } from 'react-router-dom';

// Import Utils
import NativeActions from 'utils/NativeActions';

const Recharge = {
  path: 'recharge',
  Component: () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    useEffect(() => {
      NativeActions.trackEvent('Recharge | Recharge Opened');
      window.paymentCallback = (data) => {
        queryClient.invalidateQueries();
        // const data = {transaction_id: 123, display_transaction_id: "123", extra_data: { mobile_recharge_reference_number: '688079306161539347260350'}}
        navigate('/recharge/status', { state: data });
      };
    }, []);

    return <Outlet />;
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const Contacts = await import('routes/Recharge/Contacts/Contacts');
        return { Component: Contacts.default };
      },
    },
    {
      path: 'plans',
      lazy: async () => {
        const Plans = await import('routes/Recharge/Plans/Plans');
        return { Component: Plans.default };
      },
    },
    {
      path: 'status',
      lazy: async () => {
        const Status = await import('routes/Recharge/Status/Status');
        return { Component: Status.default };
      },
    },
  ],
};

export default Recharge;
