// Import Modules
import { RouteObject } from 'react-router-dom';

const PinActivation: RouteObject = {
  path: 'pin-activate',
  children: [
    {
      index: true,
      lazy: async () => {
        const PinMain = await import('routes/PinActivation/PinMain/PinMain');
        return { Component: PinMain.default };
      },
    },
    {
      path: 'iframe',
      lazy: async () => {
        const PinIframe = await import('routes/PinActivation/PinIframe/PinIframe');
        return { Component: PinIframe.default };
      },
    },
  ],
};

export default PinActivation;
