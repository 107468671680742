// Import Modules
import { RouteObject } from 'react-router-dom';

// Import Routes
import Rewards from 'routes/Rewards/Rewards';
import QNA from 'routes/QNA/QNA';
import FAQ from 'routes/FAQ/FAQ';
import Recharge from 'routes/Recharge/Recharge';
import AutoReload from 'routes/AutoReload/AutoReload';
import Electricity from 'routes/Electricity/Electricity';
import DigiGold from 'routes/DigiGold/DigiGold';
import Subscriptions from 'routes/Subscriptions/Subscriptions';
import SupportCenter from 'routes/SupportCenter/SupportCenter';
import CardActivation from 'routes/CardActivation/CardActivation';
import PinActivation from 'routes/PinActivation/PinActivation';
import GiftCards from 'routes/GiftCards/GiftCards';
import KYCPaywall from 'routes/KYCPaywall/KYCPaywall';
import NPS from 'routes/NPS/NPS';
import Limits from 'routes/Limits/Limits';
import AppCustomisations from 'routes/AppCustomisations/AppCustomisations';
import Keeper from 'routes/Keeper/Keeper';
import PaidCallSupport from 'routes/PaidCallSupport/PaidCallSupport';
import Beneficiary from 'routes/Beneficiary/Beneficiary';

const Routes: RouteObject[] = [
  {
    path: 'hc-screen',
    index: true,
    lazy: async () => {
      const HCScreen = await import('routes/HCScreen/HCScreen');
      return { Component: HCScreen.default };
    },
  },
  Rewards,
  {
    path: 'raffle-tickets/:raffleId',
    index: true,
    lazy: async () => {
      const RaffleTickets = await import('routes/RaffleTickets/RaffleTickets');
      return { Component: RaffleTickets.default };
    },
  },
  QNA,
  FAQ,
  Recharge,
  AutoReload,
  Electricity,
  DigiGold,
  {
    path: 'platform-fee/:txnId',
    lazy: async () => {
      const Invoice = await import('routes/Invoice/Invoice');
      return { Component: Invoice.default };
    },
  },
  {
    path: 'invoice/:invoiceId',
    lazy: async () => {
      const ViewInvoice = await import('routes/ViewInvoice/ViewInvoice');
      return { Component: ViewInvoice.default };
    },
  },
  Subscriptions,
  {
    path: 'pdf-viewer',
    lazy: async () => {
      const PDFViewer = await import('routes/PDFViewer/PDFViewer');
      return { Component: PDFViewer.default };
    },
  },
  SupportCenter,
  CardActivation,
  PinActivation,
  {
    path: 'primary-ecom-enable',
    lazy: async () => {
      const PrimaryEcomEnable = await import('routes/PrimaryEcomEnable/PrimaryEcomEnable');
      return { Component: PrimaryEcomEnable.default };
    },
  },
  {
    path: 'primary-tap-enable',
    lazy: async () => {
      const PrimaryTapEnable = await import('routes/PrimaryTapEnable/PrimaryTapEnable');
      return { Component: PrimaryTapEnable.default };
    },
  },
  {
    path: 'templates',
    lazy: async () => {
      const Templates = await import('routes/Templates/Templates');
      return { Component: Templates.default };
    },
  },
  GiftCards,
  Limits,
  KYCPaywall,
  NPS,
  {
    path: '/bank-transfer',
    lazy: async () => {
      const BankTransfer = await import('routes/BankTransfer/BankTransfer');
      return { Component: BankTransfer.default };
    },
  },
  AppCustomisations,
  Keeper,
  {
    path: 'kyc-survey',
    lazy: async () => {
      const KYCSurvey = await import('routes/KYCSurvey/KYCSurvey');
      return { Component: KYCSurvey.default };
    },
  },
  PaidCallSupport,
  Beneficiary,
];

export default Routes;
