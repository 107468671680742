// Import Modules
import { RouteObject } from 'react-router-dom';

const NPS: RouteObject = {
  path: 'nps',
  children: [
    {
      path: 'intro/:slug',
      lazy: async () => {
        const NPSIntro = await import('./NPSIntro/NPSIntro');
        return { Component: NPSIntro.default };
      },
    },
    {
      path: ':slug',
      lazy: async () => {
        const NPSSurvey = await import('./NPSSurvey/NPSSurvey');
        return { Component: NPSSurvey.default };
      },
    },
  ],
};

export default NPS;
