// Import Modules
import { StrictMode, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Import Routes
import Routes from 'routes/routes';

// Import Components
import Error from 'components/GeneralComponents/Error/Error';

// Import Endpoints
import { SENTRY_URL } from 'config/Endpoints';

if (import.meta.env.VITE_ENV === 'PRODUCTION') {
  const username = localStorage.getItem('username');
  Sentry.init({
    dsn: SENTRY_URL,
    release: '1.0.0',
    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
    ignoreErrors: ['dynamically imported module'],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({ maskAllText: true, blockAllMedia: true }),
      new Sentry.BrowserProfilingIntegration(),
    ],
  });
  Sentry.setUser({ username: username as string });
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: Infinity, retry: false } },
});

const router = sentryCreateBrowserRouter(
  [
    {
      path: '/',
      children: Routes,
      errorElement: <Error />,
      lazy: async () => {
        const App = (await import('App')).default;
        return {
          Component: () => {
            return (
              // eslint-disable-next-line react/no-unstable-nested-components
              <Sentry.ErrorBoundary fallback={({ error }) => <Error error={error} />}>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </Sentry.ErrorBoundary>
            );
          },
        };
      },
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
      v7_fetcherPersist: true,
      v7_prependBasename: true,
      v7_relativeSplatPath: true,
    },
  },
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={({ error }) => <Error error={error} />}>
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </Sentry.ErrorBoundary>
  </StrictMode>,
);
