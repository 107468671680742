// Import Modules
import styled from 'styled-components';

// Import Types
import { ButtonProps } from './Button.types';

const sizes = {
  m: { height: 32, fontSize: 14, lineHeight: 20 },
  l: { height: 40, fontSize: 14, lineHeight: 20 },
  xl: { height: 48, fontSize: 16, lineHeight: 24 },
};

const colors = {
  primary: '#000000cc',
  secondary: '#FBAF03',
  tertiary: '#FBAF03',
  critical: '#ffffffe6',
};

const background = {
  primary: {
    enabled: '#FBAF03',
    highlight:
      'radial-gradient(39.7% 493.33% at 49.65% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(242, 241, 241, 0.5) 25.94%, rgba(212, 210, 210, 0.345) 63.65%, rgba(184, 180, 180, 0.025) 100%), #FBAF03',
  },
  secondary: {
    enabled: '#262626',
    highlight:
      'radial-gradient(39.7% 493.33% at 49.65% 50%, rgba(255, 255, 255, 0.1) 0%, rgba(242, 241, 241, 0.1) 25.94%, rgba(212, 210, 210, 0.069) 63.65%, rgba(184, 180, 180, 0.005) 100%), #121212;',
  },
  tertiary: { enabled: 'transparent', highlight: 'transparent' },
  critical: { enabled: '#872A32', highlight: '#ED4A58' },
};

export const ButtonStyles = styled.button<ButtonProps>`
  padding: 0px;
  border: none;
  outline: none;
  border-radius: 32px;
  position: relative;
  height: ${({ size }) => `${[sizes[size ?? 'l'].height]}px`};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  background-color: transparent;
  .wrap {
    font-weight: 600;
    height: 100%;
    padding: 12px 16px;
    border-radius: 32px;

    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: ${({ variant }) => colors[variant ?? 'primary']};
    font-size: ${({ size }) => `${[sizes[size ?? 'l'].fontSize]}px`};
    line-height: ${({ size }) => `${[sizes[size ?? 'l'].fontSize]}px`};
    background: ${({ variant, state }) => background[variant ?? 'primary'][state ?? 'enabled']};

    opacity: ${({ disabled, loading }) => (disabled || loading ? 0.5 : 1)};

    &:active {
      opacity: ${({ disabled, loading }) => (disabled || loading ? 0.5 : 0.7)};
    }
    .text {
      display: flex;
      text-align: center;
      align-items: center;
      gap: 8px;
      opacity: ${({ loading }) => (loading ? 0 : 1)};
    }
  }

  .spinner {
    width: 70px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    div {
      width: 8px;
      height: 8px;
      background-color: #ffffff;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }
    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
      }
      40% {
        -webkit-transform: scale(1);
      }
    }

    @keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      40% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
`;
