// Import Modules
import { RouteObject } from 'react-router-dom';

const GiftCards: RouteObject = {
  path: 'gift-cards',
  children: [
    {
      index: true,
      lazy: async () => {
        const GiftCardsHome = await import('./GiftCardsHome/GiftCardsHome');
        return { Component: GiftCardsHome.default };
      },
    },
    {
      path: 'all-vouchers',
      lazy: async () => {
        const GiftCardsAllVouchers = await import('./GiftCardsAllVouchers/GiftCardsAllVouchers');
        return { Component: GiftCardsAllVouchers.default };
      },
    },
    {
      path: 'buy/:id',
      lazy: async () => {
        const GiftCardsBuy = await import('./GiftCardsBuy/GiftCardsBuy');
        return { Component: GiftCardsBuy.default };
      },
    },
    {
      path: 'category/:category',
      lazy: async () => {
        // Example for element method
        const GiftCardsCategory = (await import('./GiftCardsCategory/GiftCardsCategory')).default;
        return { element: <GiftCardsCategory /> };
      },
    },
    {
      path: 'search',
      lazy: async () => {
        // Example for Componenet method
        const GiftCardsSearch = (await import('./GiftCardsSearch/GiftCardsSearch')).default;
        return { Component: () => <GiftCardsSearch /> };
      },
    },
  ],
};

export default GiftCards;
