// Endpoints Config File
const ENVIRONMENT = import.meta.env.VITE_ENV;

const WESTEROS_URL = import.meta.env.VITE_WESTEROS_URL;
export const HANDOFKING_URL = import.meta.env.VITE_HANDOFKING_URL;
export const HALFBLOOD_URL = import.meta.env.VITE_HALFBLOOD_URL;

const PROD = ENVIRONMENT === 'PRODUCTION';
const BETA = ENVIRONMENT === 'BETA';

const DEBUG_URL = localStorage.getItem('debug-url');
const HANDOFKING_DEBUG_URL = localStorage.getItem('handofking-debug-url');
const HALFBLOOD_DEBUG_URL = localStorage.getItem('halfblood-debug-url');

// ******************************************** WESTEROS ******************************************** //
export const API_ENDPOINT = PROD ? WESTEROS_URL : DEBUG_URL || WESTEROS_URL;

// ******************************************** HANDOFKING ******************************************** //
export const HANDOFKING_API_ENDPOINT = PROD ? HANDOFKING_URL : HANDOFKING_DEBUG_URL || HANDOFKING_URL;

// ******************************************** HALFBLOOD ******************************************** //
export const HALFBLOOD_API_ENDPOINT = PROD ? HALFBLOOD_URL : HALFBLOOD_DEBUG_URL || HALFBLOOD_URL;

// ******************************************** MISC ******************************************** //
export const CDN_ENDPOINT = 'https://cdn.famapp.co.in/prod';
export const SENTRY_URL = 'https://03e45ce2700a455b9bf5df69433b54f5@sentry-op.fampay.in/16';
export const MERCHANT_ID_DIGIGOLD = PROD || BETA ? '5929867' : '1';
export const FAMX_ULTRA_MERCHANT_ID = PROD || BETA ? '14527303' : '3';
export const FAMX_PLUS_MERCHANT_ID = PROD || BETA ? '14527279' : '3';
export const FAMX_PRO_MERCHANT_ID = PROD || BETA ? '40145193' : '3';
export const AUTOSAVE_MERCHANT_ID = PROD || BETA ? '36887786' : '5';
export const PAID_CALL_SUPPORT_MERCHANT_ID = PROD || BETA ? '46229424' : '3';
