import styled from 'styled-components';

export const ErrorStyles = styled.div`
  height: 100vh;
  padding: 104px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: #1e1e1e;
  box-sizing: border-box;
  background-image: linear-gradient(180deg, rgba(237, 74, 88, 0.28) 0%, rgba(139, 14, 25, 0) 240px);

  .heading {
    font-size: 28px;
    font-weight: 800;
    line-height: 36px;
    color: rgba(255, 255, 255, 0.9);
  }

  .subheading {
    margin-top: 4px;
    font-size: 14px;
    min-height: 80px;
    line-height: 20px;
    text-align: center;
    color: rgba(255, 255, 255, 0.55);
    text-decoration: ${({ showError }) => (showError ? 'none' : 'underline')};
  }

  img {
    margin: 34px auto 32px;
  }
`;
