// Import Modules
import { RouteObject } from 'react-router-dom';

const Beneficiary: RouteObject = {
  path: 'beneficiary',
  children: [
    {
      index: true,
      path: '',
      lazy: async () => {
        const Home = await import('./Home/Home');
        return { Component: Home.default };
      },
    },
  ],
};

export default Beneficiary;
