// Import Modules
import { RouteObject } from 'react-router-dom';

const Keeper: RouteObject = {
  path: 'keeper',
  children: [
    {
      index: true,
      lazy: async () => {
        const KeeperMain = await import('routes/Keeper/KeeperMain/KeeperMain');
        return { Component: KeeperMain.default };
      },
    },
    {
      path: 'onboarding',
      lazy: async () => {
        const Onboarding = await import('routes/Keeper/Onboarding/Onboarding');
        return { Component: Onboarding.default };
      },
    },
    {
      path: 'autosave-edit',
      lazy: async () => {
        const AutoSaveEdit = await import('./AutoSaveEdit/AutoSaveEdit');
        return { Component: AutoSaveEdit.default };
      },
    },
    {
      path: 'history',
      lazy: async () => {
        const History = await import('./History/History');
        return { Component: History.default };
      },
    },
    {
      path: 'faq',
      lazy: async () => {
        const History = await import('./KeeperFAQ/KeeperFAQ');
        return { Component: History.default };
      },
    },
  ],
};

export default Keeper;
