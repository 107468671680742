import { RouteObject } from 'react-router-dom';

import MyGiftCards from 'routes/Rewards/MyGiftCards/MyGiftCards';

const Rewards: RouteObject = {
  path: 'rewards',
  children: [
    {
      index: true,
      lazy: async () => {
        const MainRewards = await import('routes/Rewards/MainReward/MainReward');
        return { Component: MainRewards.default };
      },
    },
    {
      path: 'my-cash-history',
      lazy: async () => {
        const MyCashHistory = await import('routes/Rewards/MyCashHistory/MyCashHistory');
        return { Component: MyCashHistory.default };
      },
    },
    {
      path: 'my-cash',
      lazy: async () => {
        const MyCash = await import('routes/Rewards/MyCash/MyCash');
        return { Component: MyCash.default };
      },
    },
    {
      path: 'my-coins',
      lazy: async () => {
        const MyCoins = await import('routes/Rewards/MyCoins/MyCoins');
        return { Component: MyCoins.default };
      },
    },
    {
      path: 'my-coupons',
      lazy: async () => {
        const MyCoupons = await import('routes/Rewards/MyCoupons/MyCoupons');
        return { Component: MyCoupons.default };
      },
    },
    {
      path: 'my-other-rewards',
      lazy: async () => {
        const MyOtherRewards = await import('routes/Rewards/MyOtherRewards/MyOtherRewards');
        return { Component: MyOtherRewards.default };
      },
    },
    {
      path: 'my-deal-offers/:dealId',
      lazy: async () => {
        const MyDealOffers = await import('routes/Rewards/MyDealOffers/MyDealOffers');
        return { Component: MyDealOffers.default };
      },
    },
    {
      path: 'my-offer/:offerId',
      lazy: async () => {
        const OfferDetails = await import('routes/Rewards/OfferDetails/OfferDetails');
        return { Component: OfferDetails.default };
      },
    },
    {
      path: 'reward/:rewardId',
      lazy: async () => {
        const RedeemReward = (await import('routes/Rewards/RedeemReward/RedeemReward')).default;
        return {
          Component: () => <RedeemReward endpoint="user_reward" />,
        };
      },
    },
    {
      path: 'event-reward/:rewardId',
      lazy: async () => {
        const RedeemReward = (await import('routes/Rewards/RedeemReward/RedeemReward')).default;
        return { Component: () => <RedeemReward endpoint="coin/event_reward" /> };
      },
    },
    {
      path: 'claimed-reward/:rewardId',
      lazy: async () => {
        const ClaimedReward = await import('routes/Rewards/ClaimedReward/ClaimedReward');
        return { Component: ClaimedReward.default };
      },
    },
    {
      path: 'my-giftcards',
      children: MyGiftCards,
    },
  ],
};

export default Rewards;
