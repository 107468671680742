const CardActivation = {
  path: 'card-activate',
  children: [
    {
      path: 'success',
      lazy: async () => {
        const ActivationSuccess = await import('routes/CardActivation/ActivationSuccess/ActivationSuccess');
        return { Component: ActivationSuccess.default };
      },
    },
    {
      path: 'login',
      lazy: async () => {
        const Login = await import('routes/Login/Login');
        return { Component: Login.default };
      },
    },
    {
      path: ':code',
      lazy: async () => {
        const ActivationHome = await import('routes/CardActivation/ActivationHome/ActivationHome');
        return { Component: ActivationHome.default };
      },
    },
  ],
};

export default CardActivation;
