// This File Contains all the Actions which are used in Native Android/iOS

const NativeActions = {
  handleNativeDeeplink(deeplink: string) {
    window.NativeActionsAndroid?.handleNativeDeeplink?.(deeplink); // For Android
    window.webkit?.messageHandlers?.handleNativeDeeplink?.postMessage({
      deeplink,
    }); // For iOS
    if (window.isReactNativeApp) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'handleNativeDeeplink', deeplink }));
    }
  },

  handleError(errorCode: string, alertData: any) {
    const alert = JSON.stringify(alertData);
    window.NativeActionsAndroid?.handleNativeAlert?.(errorCode, alert); // For Android
    window.webkit?.messageHandlers?.handleNativeAlert?.postMessage({ errorCode, alert }); // For iOS
  },

  getCurrentLocation() {
    // {
    // "api_address": "Daskroi Taluka, Ahmedabad District, Gujarat, 382433, India",
    // "city": "daskroi taluka",
    // "country": "india",
    // "latitude": 23.0141888,
    // "longitude": 72.6724037,
    // "pincode": "382433",
    // "state": "gujarat"
    // }
    const latitude = localStorage.getItem('latitude');
    const longitude = localStorage.getItem('longitude');
    const appLocation = window.NativeActionsAndroid?.getCurrentLocation?.();
    return appLocation || JSON.stringify({ latitude, longitude }); // Android Sends String
  },

  getUserAgentHeader() {
    if (window?.NativeActionsAndroid) {
      const userAgentHeader = window?.NativeActionsAndroid?.getUserAgentHeader?.(); // Android
      return userAgentHeader;
    }

    const userAgentHeader = localStorage.getItem('device-details') || window.navigator.userAgent; // iOS
    return userAgentHeader;
  },

  showToast(message: string) {
    window.NativeActionsAndroid?.showToast?.(message);
    window.webkit?.messageHandlers?.showToast?.postMessage({ message }); // For iOS
    if (window.isReactNativeApp) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'showToast', message }));
    }
  },

  hasContactsPermission() {
    if (window.ContactActionsAndroid) return window.ContactActionsAndroid?.hasContactsPermission?.(); // For Android
    if (window.webkit?.messageHandlers) {
      // For iOS + iOS can't return anything
      window.webkit?.messageHandlers?.hasContactsPermission?.postMessage({ message: 'hasContactsPermission' });
    }
    if (window.isReactNativeApp) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'hasContactsPermission' }));
    }
    return false;
  },

  getContactsPermission() {
    // Async
    window.ContactActionsAndroid?.getContactsPermission?.(); // For Android
    window.webkit?.messageHandlers?.getContactsPermission?.postMessage({ message: 'getContactsPermission' }); // For iOS
    if (window.isReactNativeApp) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'getContactsPermission' }));
    }
  },

  getNativeContacts() {
    // Async
    window.ContactActionsAndroid?.getNativeContacts?.(); // For Android
    window.webkit?.messageHandlers?.getNativeContacts?.postMessage({ message: 'getContacts' }); // For iOS
    if (window.isReactNativeApp) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'getContacts' }));
    }
  },

  getUserDetails() {
    if (window.ContactActionsAndroid) {
      const user = window.NativeAuthAndroid?.getUserDetails?.(); // For Android
      return user ? JSON.parse(user) : null; // Android returns string
    }
    if (window.webkit?.messageHandlers) {
      // For iOS + iOS can't return anything
      window.webkit?.messageHandlers?.getUserDetails?.postMessage({ message: 'getUserDetails' });
    }
    if (window.isReactNativeApp) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'getUserDetails' }));
    }
    return false;
  },

  getUserCohorts() {
    if (window.ContactActionsAndroid) {
      const userCohorts = window.NativeAuthAndroid?.getUserCohorts?.(); // For Android
      return userCohorts ? userCohorts.split(',') : null; // Android returns string
    }
    if (window.webkit?.messageHandlers) {
      // For iOS + iOS can't return anything
      window.webkit?.messageHandlers?.getUserCohorts?.postMessage({ message: 'getUserCohorts' });
      if (window.webkit?.messageHandlers?.getUserCohorts) return true;
    }
    if (window.isReactNativeApp) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'getUserCohorts' }));
      return true;
    }
    return false;
  },

  getAuthToken() {
    return window.NativeAuthAndroid?.getAuthToken?.() || localStorage.getItem('user-auth');
  },

  trackEvent(eventName: string, properties?: any) {
    window.NativeActionsAndroid?.triggerSegmentEvent?.(eventName, JSON.stringify(properties), 'track');
    window.webkit?.messageHandlers?.triggerEvent?.postMessage({
      eventName,
      properties: JSON.stringify(properties),
      type: 'track',
    }); // For iOS
    if (window.isReactNativeApp) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'trackEvent', eventName, properties }));
    }
  },

  setCTUserProperty(key: string, value: string) {
    window.NativeActionsAndroid?.setCTUserProperty?.(key, value); // For Android
    window.webkit?.messageHandlers?.setCTUserProperty?.postMessage({ key, value }); // For iOS
  },

  trackScreen(eventName: string, properties?: any) {
    window.NativeActionsAndroid?.triggerSegmentEvent?.(eventName, JSON.stringify(properties), 'screen');
    window.webkit?.messageHandlers?.triggerEvent?.postMessage({
      eventName,
      properties: JSON.stringify(properties),
      type: 'screen',
    }); // For iOS
    if (window.isReactNativeApp) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'trackScreen', eventName, properties }));
    }
  },

  toggleNavigationItems(toggle: boolean) {
    window.NativeActionsAndroid?.toggleNavigationItems?.(toggle); // For Android
    window.webkit?.messageHandlers?.toggleNavigationItems?.postMessage?.({ navigationItems: toggle });
  },
  openChatBot() {
    window.location.replace(`fp://fampay.in/support/bot/`);
  },
};

export default NativeActions;
