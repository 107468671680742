import { RouteObject } from 'react-router-dom';

const GiftCards: RouteObject[] = [
  {
    index: true,
    lazy: async () => {
      const AllGiftCards = await import('routes/Rewards/MyGiftCards/AllGiftCards/AllGiftCards');
      return { Component: AllGiftCards.default };
    },
  },
  {
    path: ':id',
    lazy: async () => {
      const GiftCardDetails = await import('routes/Rewards/MyGiftCards/GiftCardDetails/GiftCardDetails');
      return { Component: GiftCardDetails.default };
    },
  },
];

export default GiftCards;
