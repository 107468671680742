const QNARoutes = {
  path: 'qna/:slug',
  lazy: async () => {
    const QNA = (await import('routes/QNA/QNAHolder')).default;
    return {
      Component: () => {
        return <QNA />;
      },
    };
  },
  children: [
    {
      path: 'answers',
      lazy: async () => {
        const SurveyResponse = await import('routes/QNA/SurveyResponse/SurveyResponse');
        return {
          Component: SurveyResponse.default,
        };
      },
    },
    {
      path: 'feedback',
      lazy: async () => {
        const FeedbackScreen = await import('routes/QNA/FeedbackScreen/FeedbackScreen');
        return {
          Component: FeedbackScreen.default,
        };
      },
    },
    {
      path: ':quesIndex?',
      index: true,
      lazy: async () => {
        const QNAMain = await import('routes/QNA/QNAMain');
        return {
          Component: QNAMain.default,
        };
      },
    },
  ],
};

export default QNARoutes;
