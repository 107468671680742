// Import Modules
import { RouteObject, Outlet, useLocation } from 'react-router-dom';

const SupportCenter: RouteObject = {
  path: 'support-center',
  lazy: async () => {
    const GeneralContainer = (await import('components/GeneralComponents/GeneralContainer/GeneralContainer')).default;
    return {
      Component: () => {
        const location = useLocation();
        const loc = location.pathname.split('/');
        const questionsPage = Boolean(loc[loc.length - 2] === 'questions');
        const searchPage = Boolean(loc[loc.length - 1] === 'search');

        return (
          <GeneralContainer parentBg="#1e1e1e" containerBg="#121212">
            {!searchPage && !questionsPage && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '200px',
                  top: 0,
                  background: `linear-gradient(182.54deg, rgba(255, 196, 108, 0.2) 4.25%, rgba(102, 61, 0, 0) 91.22%)`,
                }}
              />
            )}
            <Outlet />
          </GeneralContainer>
        );
      },
    };
  },

  children: [
    {
      index: true,
      lazy: async () => {
        const SupportHome = await import('routes/SupportCenter/SupportHome/SupportHome');
        return { Component: SupportHome.default };
      },
    },
    {
      path: 'search',
      lazy: async () => {
        const SearchSupportQuestions = await import(
          'routes/SupportCenter/SearchSupportQuestions/SearchSupportQuestions'
        );
        return { Component: SearchSupportQuestions.default };
      },
    },
    // {
    //   path: 'my-transactions',
    //   lazy: async () => {
    //     const MyTransactions = await import('routes/SupportCenter/MyTransactions/MyTransactions');
    //     return { Component: MyTransactions.default };
    //   },
    // },
    {
      path: ':slug',
      lazy: async () => {
        const SupportHome = await import('routes/SupportCenter/SupportHome/SupportHome');
        return { Component: SupportHome.default };
      },
    },
    {
      path: ':slug/:category',
      lazy: async () => {
        const SupportSubCategories = (await import('routes/SupportCenter/SubCategories/SupportSubCategories')).default;
        return { Component: () => <SupportSubCategories isQuestion="false" /> };
      },
    },
    {
      path: ':slug/:category/questions',
      lazy: async () => {
        const SupportSubCategories = (await import('routes/SupportCenter/SubCategories/SupportSubCategories')).default;
        return { Component: () => <SupportSubCategories isQuestion="true" /> };
      },
    },
    {
      path: ':slug/:category/questions/:questionid',
      lazy: async () => {
        const SupportQuestion = await import('routes/SupportCenter/SupportQuestion/SupportQuestion');
        return { Component: SupportQuestion.default };
      },
    },
    {
      path: '*',
      lazy: async () => {
        const FAQ404 = await import('routes/FAQ/FAQ404/FAQ404');
        return { Component: FAQ404.default };
      },
    },
  ],
};

export default SupportCenter;
