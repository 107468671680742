// Import Modules
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, RouteObject, Outlet } from 'react-router-dom';

// Import Utils
import NativeActions from 'utils/NativeActions';

const DigiGold: RouteObject = {
  path: 'digigold',
  Component: () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    useEffect(() => {
      NativeActions.trackEvent('DigiGold', { screen: 'Home Page', action: 'Opened' });
      window.paymentCallback = (data) => {
        queryClient.invalidateQueries({ queryKey: ['user-wallet'] });
        queryClient.invalidateQueries({ queryKey: ['metal-price'] });
        /** Data Format
         * { "transaction_id": 63, "extra_data": " {\"metal_order_metadata\":{\"metal_type\":\"gold\",\"lock_price\":527.584,\"block_id\":\"d9BQZw67\",\"quantity\":null,\"pre_tax_amount\":1100}} "}
         */
        navigate('/digigold/status', { state: data });
      };
    }, []);
    return <Outlet />;
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const DigiGoldHome = await import('./DigiGoldHome/DigiGoldHome');
        return { Component: DigiGoldHome.default };
      },
    },
    {
      path: 'intro',
      lazy: async () => {
        const DigiGoldIntro = await import('./DigiGoldIntro/DigiGoldIntro');
        return { Component: DigiGoldIntro.default };
      },
    },
    {
      path: 'buy',
      lazy: async () => {
        const DigiGoldBuy = await import('./DigiGoldBuy/DigiGoldBuy');
        return { Component: DigiGoldBuy.default };
      },
    },
    {
      path: 'sell',
      lazy: async () => {
        const DigiGoldSell = await import('./DigiGoldSell/DigiGoldSell');
        return { Component: DigiGoldSell.default };
      },
    },
    {
      path: 'history',
      lazy: async () => {
        const DigiGoldTransactions = await import('./Transactions/DigiGoldTransactions');
        return { Component: DigiGoldTransactions.default };
      },
    },
    {
      path: 'share',
      lazy: async () => {
        const DigiGoldShare = await import('./DigiGoldShare/DigiGoldShare');
        return { Component: DigiGoldShare.default };
      },
    },
    {
      path: 'status',
      lazy: async () => {
        const DigiGoldStatus = await import('./DigiGoldStatus/DigiGoldStatus');
        return { Component: DigiGoldStatus.default };
      },
    },
    {
      path: 'invoice/:id',
      lazy: async () => {
        const DigiGoldInvoice = await import('./DigiGoldInvoice/DigiGoldInvoice');
        return { Component: DigiGoldInvoice.default };
      },
    },
  ],
};

export default DigiGold;
