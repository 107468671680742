// Import Modules
import { FC } from 'react';

// Import Types
import { ButtonProps } from './Button.types';

// Import Styles
import { ButtonStyles } from './Button.styles';

const Button: FC<ButtonProps> = ({
  children,
  onClick,
  size = 'l',
  variant = 'primary', // 'primary' | 'secondary' | 'tertiary' | 'critical'
  state = 'enabled', // 'enabled' | 'highlight'
  loading = false,
  disabled,
  fullWidth,
  style,
  wrapStyle,
  textStyle,
}) => {
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (!loading && onClick) onClick(e);
  };

  return (
    <ButtonStyles
      onClick={onButtonClick}
      size={size}
      variant={variant}
      state={state}
      loading={loading}
      disabled={disabled}
      fullWidth={fullWidth}
      style={style}
    >
      {loading && (
        <div className="spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      )}
      <div className="wrap" style={wrapStyle}>
        <div className="text" style={textStyle}>
          {children}
        </div>
      </div>
    </ButtonStyles>
  );
};

export default Button;
