// Import Modules
import { useEffect } from 'react';
import { Outlet, RouteObject, useNavigate } from 'react-router-dom';

const Limits: RouteObject = {
  path: 'limits',
  Component: () => {
    const navigate = useNavigate();

    useEffect(() => {
      window.paymentCallback = (data) => {
        navigate('/subscriptions/status', { state: data, replace: true });
      };
    }, []);

    return <Outlet />;
  },
  children: [
    {
      index: true,
      path: '/limits*',
      lazy: async () => {
        const PaymentLimits = await import('./PaymentLimits/PaymentLimits');
        return { Component: PaymentLimits.default };
      },
    },
  ],
};

export default Limits;
